<template>
    <component :is="form === undefined ? 'div' : 'b-card'">
        <template>
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <b-row class="mt-3">
                        <!-- Name -->
                        <b-col cols="12" sm="6" md="6">
                            <validation-provider #default="validationContext" name="name" rules="required">
                                <b-form-group label="Name" label-for="name">
                                    <b-form-input id="name" v-model="form.name" :state="getValidationState(validationContext)" trim />

                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Arabic Name -->
                        <b-col cols="12" sm="6" md="6">
                            <validation-provider #default="validationContext" name="arabicName" rules="required">
                                <b-form-group label="Arabic Name" label-for="arabicName">
                                    <b-form-input id="arabicName" v-model="form.name_ar" :state="getValidationState(validationContext)" trim />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Slug -->
                        <b-col cols="12" sm="6" md="6">
                            <validation-provider name="subject">
                                <b-form-group label="Subject" label-for="subject">
                                    <b-form-input id="slug-name" v-model="form.subject" trim />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" sm="6" md="6">
                            <validation-provider name="arabicSubject">
                                <b-form-group label="Arabic Subject" label-for="arabicSubject">
                                    <b-form-input id="arabicSubject" v-model="form.subject_ar" trim />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <!-- Form Actions -->
                        <b-col cols="12">
                            <div class="d-flex mt-2">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit">
                                    {{ genreId ? "update" : "add" }}
                                </b-button>
                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-danger"> reset </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </template>
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import usegenreList from "./usegenreList";

const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    setup() {
        let blankForm = {};

        const genreId = ref(null);

        const form = ref(JSON.parse(JSON.stringify(blankForm)));

        if (router.currentRoute.params.id) {
            genreId.value = router.currentRoute.params.id;
        }

        const { storeGenres, updatePlaylist, showPlaylist } = usegenreList();
        // Register module


        const onSubmit = async () => {
            if (genreId.value) {
                updatePlaylist(genreId.value, form.value).then((res) => {
                    router.push({ name: "apps-genres-list" });
                });
            } else {
                console.log(form.value);
                storeGenres(form.value).then((res) => {
                    router.push({ name: "apps-genres-list" });
                });
            }
        };

        // Reset Validation
        const resetData = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };

        const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

        // fill Form When edit page
        const showData = async () => {
            const response = await showPlaylist(genreId.value);
            if (response) {
                form.value = { ...blankForm, ...response };
                blankForm = { ...blankForm, ...response };
            } else {
                form.value = undefined;
            }
        };

        if (genreId.value) {
            showData();
        }

        return {
            form,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            required,
            genreId,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
